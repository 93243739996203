import axios from 'axios';

const BASE_URL = 'https://us-central1-mundo-informativo.cloudfunctions.net/api';

/**
 * Get all hostel advertisements with optional filtering
 * @param {Object} filters - Filter options (region, provincia, comuna) 
 * @returns {Promise} Axios response
 */
export const getPublicidadesHostels = (filters = {}) => {
  const params = {};
  
  if (filters.region) params.region = filters.region;
  if (filters.provincia) params.provincia = filters.provincia;
  if (filters.comuna) params.comuna = filters.comuna;
  
  return axios.get(`${BASE_URL}/hostels/publicidades`, { params });
};

/**
 * Get details for a specific hostel advertisement
 * @param {string} id - Hostel ID
 * @returns {Promise} Axios response
 */
export const getPublicidadHostel = (id) => {
  return axios.get(`${BASE_URL}/hostels/publicidad/${id}`);
};

/**
 * Get ranking of cities by number of hostels
 * @param {number} limit - Maximum number of cities to return
 * @returns {Promise} Axios response
 */
export const getCityRankings = (limit = 8) => {
  return axios.get(`${BASE_URL}/hostels/ranking-ciudades`, { 
    params: { limit }
  });
};
