<template>
  <div class="container mt-5 pt-5">
    <div class="row mb-4">
      <div class="col-12">
        <button @click="goBack" class="btn btn-secondary">
          <i class="fas fa-arrow-left me-2"></i> Volver
        </button>
      </div>
    </div>

    <div v-if="loading" class="text-center py-5">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Cargando...</span>
      </div>
    </div>

    <div v-else-if="error" class="alert alert-danger">
      {{ error }}
    </div>

    <div v-else-if="hostel" class="hostel-detail">
      <div class="row">
        <div class="col-md-6">
          <div id="hostelCarousel" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div v-for="(img, index) in hostel.imagen" :key="index" 
                class="carousel-item" :class="{ active: index === 0 }">
                <img :src="img" class="d-block w-100" alt="Imagen del hospedaje">
              </div>
              <div v-if="!hostel.imagen || hostel.imagen.length === 0" class="carousel-item active">
                <img src="https://via.placeholder.com/800x500?text=No+hay+imagenes" class="d-block w-100" alt="No hay imágenes">
              </div>
            </div>
            <button v-if="hostel.imagen && hostel.imagen.length > 1" class="carousel-control-prev" type="button" 
              data-bs-target="#hostelCarousel" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button v-if="hostel.imagen && hostel.imagen.length > 1" class="carousel-control-next" type="button" 
              data-bs-target="#hostelCarousel" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>

        <div class="col-md-6">
          <h1 class="hostel-title">{{ hostel.titulo }}</h1>
          <div class="location mb-3">
            <i class="fas fa-map-marker-alt"></i> 
            {{ hostel.comuna }}, {{ hostel.provincia }}, {{ hostel.region }}
          </div>
          
          <div v-if="hostel.promocion && hostel.promocion[0] !== 'sinPromocion'" class="promotion-tag mb-3">
            {{ hostel.promocion[0] }}
          </div>
          
          <div class="description mb-4">
            <h4>Descripción</h4>
            <p>{{ hostel.descripcion }}</p>
          </div>
          
          <div class="amenities mb-4">
            <h4>Características</h4>
            <div class="row">
              <div class="col-6">
                <p><i class="fas fa-bed"></i> {{ hostel.habitaciones }} Habitaciones</p>
                <p><i class="fas fa-bath"></i> {{ hostel.baños }} Baños</p>
                <p v-if="hostel.wifi"><i class="fas fa-wifi"></i> WiFi</p>
              </div>
              <div class="col-6">
                <p v-if="hostel.cocinaCompartida"><i class="fas fa-utensils"></i> Cocina compartida</p>
                <p v-if="hostel.admiteMascotas"><i class="fas fa-dog"></i> Admite mascotas</p>
                <p v-if="hostel.aceptaPareja"><i class="fas fa-heart"></i> Acepta parejas</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <hr class="my-4">
      
      <div class="rooms-section">
        <h2 class="section-title mb-4">Habitaciones Disponibles</h2>
        
        <div v-if="!hostel.rooms || hostel.rooms.length === 0" class="alert alert-info">
          No hay información de habitaciones disponibles.
        </div>
        
        <div v-else class="rooms-container">
          <div class="row">
            <div v-for="room in hostel.rooms" :key="room.id" class="col-md-4 mb-4">
              <div class="card room-card h-100">
                <div class="image-container">
                  <img :src="room.fotos && room.fotos.length > 0 ? room.fotos[0] : 'https://via.placeholder.com/300x200?text=No+Image'" 
                    class="card-img-top" alt="Imagen de habitación">
                </div>
                <div class="card-body">
                  <h5 class="card-title">Habitación {{ room.hostelNumber }}</h5>
                  <div class="features mb-3">
                    <span v-if="room.bañoPrivado" class="badge bg-info text-dark me-1">Baño privado</span>
                    <span v-if="room.frigobar" class="badge bg-info text-dark me-1">Frigobar</span>
                    <span v-if="room.aireAcondicionado" class="badge bg-info text-dark me-1">A/C</span>
                    <span v-if="room.temática" class="badge bg-info text-dark me-1">{{ room.temática }}</span>
                  </div>
                  <p v-if="room.cama" class="card-text">
                    <i class="fas fa-bed"></i> {{ room.cama }}
                  </p>
                </div>
                <div class="card-footer">
                  <button @click="viewRoomDetails(room)" class="btn btn-primary w-100">Ver Detalles</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Room Detail Modal -->
    <div class="modal fade" id="roomDetailModal" tabindex="-1" aria-labelledby="roomDetailModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="roomDetailModalLabel">
              {{ selectedRoom ? `Habitación ${selectedRoom.hostelNumber}` : 'Detalles de Habitación' }}
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" v-if="selectedRoom">
            <div class="row">
              <div class="col-md-6">
                <div id="roomCarousel" class="carousel slide" data-bs-ride="carousel">
                  <div class="carousel-inner">
                    <div v-for="(foto, index) in selectedRoom.fotos" :key="index" 
                      class="carousel-item" :class="{ active: index === 0 }">
                      <img :src="foto || 'https://via.placeholder.com/400x300?text=No+Image'" 
                        class="d-block w-100" alt="Foto de habitación">
                    </div>
                    <div v-if="!selectedRoom.fotos || selectedRoom.fotos.length === 0" 
                      class="carousel-item active">
                      <img src="https://via.placeholder.com/400x300?text=No+Image" 
                        class="d-block w-100" alt="Sin imagen">
                    </div>
                  </div>
                  <button v-if="selectedRoom.fotos && selectedRoom.fotos.length > 1" 
                    class="carousel-control-prev" type="button" 
                    data-bs-target="#roomCarousel" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Anterior</span>
                  </button>
                  <button v-if="selectedRoom.fotos && selectedRoom.fotos.length > 1" 
                    class="carousel-control-next" type="button" 
                    data-bs-target="#roomCarousel" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Siguiente</span>
                  </button>
                </div>
                
                <div v-if="selectedRoom.videos" class="mt-3">
                  <h6>Video</h6>
                  <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" 
                      :src="selectedRoom.videos" 
                      frameborder="0" allowfullscreen></iframe>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <h4>Habitación {{ selectedRoom.hostelNumber }}</h4>
                <p v-if="selectedRoom.temática">
                  <strong>Temática:</strong> {{ selectedRoom.temática }}
                </p>
                <p v-if="selectedRoom.cama">
                  <strong>Tipo de cama:</strong> {{ selectedRoom.cama }}
                </p>
                
                <h5 class="mt-3">Características</h5>
                <ul class="list-group mb-3">
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    Baño Privado
                    <span :class="selectedRoom.bañoPrivado ? 'badge bg-success' : 'badge bg-secondary'">
                      {{ selectedRoom.bañoPrivado ? 'Sí' : 'No' }}
                    </span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    Frigobar
                    <span :class="selectedRoom.frigobar ? 'badge bg-success' : 'badge bg-secondary'">
                      {{ selectedRoom.frigobar ? 'Sí' : 'No' }}
                    </span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    Aire Acondicionado
                    <span :class="selectedRoom.aireAcondicionado ? 'badge bg-success' : 'badge bg-secondary'">
                      {{ selectedRoom.aireAcondicionado ? 'Sí' : 'No' }}
                    </span>
                  </li>
                </ul>
                
                <div v-if="selectedRoom.promedio > 0">
                  <h5>Valoración</h5>
                  <div class="rating">
                    <i v-for="n in Math.round(selectedRoom.promedio)" :key="n" 
                      class="fas fa-star text-warning"></i>
                    <i v-for="n in (5 - Math.round(selectedRoom.promedio))" :key="`empty-${n}`" 
                      class="far fa-star text-warning"></i>
                    <span class="ms-2">({{ selectedRoom.promedio.toFixed(1) }})</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';
import { getPublicidadHostel } from '@/api/advertisements';

export default {
  name: 'HostelDetailView',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      hostel: null,
      loading: true,
      error: null,
      selectedRoom: null,
      roomModal: null
    };
  },
  mounted() {
    this.fetchHostelDetails();
    this.$nextTick(() => {
      this.roomModal = new Modal(document.getElementById('roomDetailModal'));
    });
  },
  methods: {
    async fetchHostelDetails() {
      this.loading = true;
      this.error = null;
      
      try {
        const response = await getPublicidadHostel(this.id);
        this.hostel = response.data;
      } catch (error) {
        console.error('Error fetching hostel details:', error);
        this.error = 'No se pudo cargar la información del hospedaje';
      } finally {
        this.loading = false;
      }
    },
    viewRoomDetails(room) {
      this.selectedRoom = room;
      this.roomModal.show();
    },
    goBack() {
      this.$router.back();
    }
  }
};
</script>

<style scoped>
.hostel-title {
  color: #4b0082;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.location {
  font-size: 1.1rem;
  color: #6c757d;
}

.promotion-tag {
  display: inline-block;
  background-color: #ff6b6b;
  color: white;
  padding: 5px 15px;
  border-radius: 20px;
  font-weight: bold;
}

.section-title {
  color: #4b0082;
  margin-top: 2rem;
}

.room-card {
  transition: transform 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.room-card:hover {
  transform: translateY(-5px);
}

.image-container {
  height: 200px;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.embed-responsive {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
}

.embed-responsive iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.features {
  display: flex;
  flex-wrap: wrap;
}
</style>
