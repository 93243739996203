import { register, login, logout, verifyToken } from '@/api/auth';
import axios from 'axios';

const API_PROD_URL = 'https://us-central1-mundo-informativo.cloudfunctions.net/api';

export default {
  namespaced: true,
  state: {
    user: {},
    token: localStorage.getItem('token') || '',
    error: null,
    isAuthenticated: !!localStorage.getItem('token')
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    LOGOUT(state) {
      state.user = {};
      state.token = '';
      state.isAuthenticated = false;
    },
    SET_AUTH(state, status) {
      state.isAuthenticated = status;
    }
  },
  actions: {
    async registerUser({ commit }, userData) {
      try {
        const data = await register(userData);
        commit('SET_USER', data.user);
        commit('SET_TOKEN', data.token);
        commit('SET_AUTH', true);
        return data;
      } catch (error) {
        commit('SET_ERROR', error.message);
        throw error;
      }
    },
    async loginUser({ commit }, userData) {
      try {
        const data = await login(userData);
        commit('SET_USER', data.user);
        commit('SET_TOKEN', data.token);
        commit('SET_AUTH', true);
        return data;
      } catch (error) {
        commit('SET_ERROR', error.message);
        throw error;
      }
    },
    logoutUser({ commit }) {
      logout();
      commit('LOGOUT');
    },
    async checkAuth({ commit }) {
      try {
        const data = await verifyToken();
        if (data && data.user) {
          commit('SET_USER', data.user);
          commit('SET_AUTH', true);
          return true;
        } else {
          commit('LOGOUT');
          return false;
        }
      } catch (error) {
        commit('LOGOUT');
        return false;
      }
    },
    async updateUserProfile({ commit, state }, userData) {
      try {
        const response = await axios.put(`${API_PROD_URL}/users/profile`, userData, {
          headers: { Authorization: `Bearer ${state.token}` }
        });
        
        // Update user in state with new data
        commit('SET_USER', { ...state.user, ...userData });
        return response.data;
      } catch (error) {
        console.error('Error updating profile:', error);
        throw new Error(error.response?.data?.error || 'Error updating profile');
      }
    },
    
    async changePassword({ state }, passwordData) {
      try {
        const response = await axios.post(`${API_PROD_URL}/users/profile/changePassword`, {
          currentPassword: passwordData.currentPassword,
          newPassword: passwordData.newPassword,
          confirmNewPassword: passwordData.confirmNewPassword
        }, {
          headers: { Authorization: `Bearer ${state.token}` }
        });
        return response.data;
      } catch (error) {
        console.error('Error changing password:', error);
        throw new Error(error.response?.data?.error || 'Error changing password');
      }
    }
  },
  getters: {
    isAuthenticated: state => state.isAuthenticated,
    currentUser: state => state.user,
    getError: state => state.error,
    getToken: state => state.token
  }
};
