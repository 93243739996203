<template>
  <div class="emergency-container">
    <div class="emergency-title">
      <h3>Números de Emergencia Chile</h3>
    </div>
    <div class="emergency-list">
      <div v-for="emergency in emergencies" :key="emergency.numero" class="emergency-card" @click="callEmergency(emergency.numero)">
        <i :class="getIcon(emergency.servicio)" class="emergency-icon"></i>
        <div class="emergency-info">
          <h4>{{ emergency.servicio }}</h4>
          <div class="emergency-number">{{ emergency.numero }}</div>
          <div class="emergency-description">{{ emergency.descripcion }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emergenciesData from '@/emergenciasChile.json';

export default {
  name: 'EmergenciasComponent',
  data() {
    return {
      emergencies: emergenciesData.numeros_emergencia_chile_2025
    }
  },
  methods: {
    callEmergency(number) {
      window.location.href = `tel:${number}`;
    },
    getIcon(service) {
      // Return the appropriate FontAwesome icon class based on the service name
      const icons = {
        "Carabineros de Chile": "fas fa-shield-alt",
        "Bomberos de Chile": "fas fa-fire-extinguisher",
        "SAMU / Ambulancia": "fas fa-ambulance",
        "PDI - Policía de Investigaciones": "fas fa-user-secret",
        "Emergencias Marítimas - Armada": "fas fa-anchor",
        "Rescate Aéreo (Fuerza Aérea de Chile)": "fas fa-helicopter",
        "Conaf - Incendios Forestales": "fas fa-tree",
        "Emergencias Viales - Seguridad en Ruta": "fas fa-car-crash",
        "Emergencias Gas Natural": "fas fa-gas-pump",
        "Emergencias Eléctricas (Enel, CGE, etc.)": "fas fa-bolt",
        "ONEMI - Oficina Nacional de Emergencia": "fas fa-exclamation-triangle",
        "Fono Drogas y Alcohol (SENDA)": "fas fa-capsules",
        "Fono Niñez": "fas fa-child",
        "Fono Mujer": "fas fa-female",
        "Fono Salud Responde (MINSAL)": "fas fa-phone-alt"
      };
      return icons[service] || "fas fa-phone";
    }
  }
}
</script>

<style scoped>
.emergency-container {
  max-width: 100%;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 10px;
}

.emergency-title {
  text-align: center;
  margin-bottom: 20px;
}

.emergency-title h3 {
  color: #dc3545;
  font-weight: bold;
}

.emergency-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 15px;
}

.emergency-card {
  display: flex;
  background-color: white;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.emergency-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.emergency-icon {
  font-size: 28px;
  color: #dc3545;
  margin-right: 15px;
  display: flex;
  align-items: center;
}

.emergency-info {
  flex: 1;
}

.emergency-info h4 {
  margin: 0 0 5px 0;
  color: #212529;
  font-size: 16px;
}

.emergency-number {
  font-weight: bold;
  color: #dc3545;
  font-size: 18px;
  margin-bottom: 5px;
}

.emergency-description {
  font-size: 14px;
  color: #6c757d;
}
</style>
