<template>
  <div class="services row" v-if="!selectedService">
    <div class="service-card col-6 col-md-4 col-lg-3" v-for="image in images" :key="image.src">
      <button @click="selectService(image.title)" class="img-btn">
        <img :src="image.src" :alt="image.title" class="img-fluid" />
        <h2 class="text-center">{{ image.title }}</h2>
      </button>
    </div>
  </div>
  <div v-else>
    <ServiceComponent :serviceTitle="selectedService" @back="resetService" />
  </div>
</template>

<script>
import ServiceComponent from './ServiceComponent.vue';

export default {
  name: 'ServicesComponent',
  components: {
    ServiceComponent
  },
  data() {
    return {
      images: [
        { src: require('@/assets/icon1.png'), title: 'Hospedajes' },
        { src: require('@/assets/icon9.png'), title: 'Comida' },
        { src: require('@/assets/icon2.png'), title: 'Preservativos' },
        { src: require('@/assets/icon4.png'), title: 'Publicistas' },
        { src: require('@/assets/icon3.png'), title: 'Transporte' },
        { src: require('@/assets/icon5.png'), title: 'Fotógrafos' },
        { src: require('@/assets/icon6.png'), title: 'Estética' },
        { src: require('@/assets/icon7.png'), title: 'Páginas' },
        { src: require('@/assets/icon8.png'), title: 'Lencería' },
        { src: require('@/assets/icon11.png'), title: 'Farmacias' },
        { src: require('@/assets/icon12.png'), title: 'Botillerías' },
        { src: require('@/assets/icon10.png'), title: 'Gimnasios' }
      ],
      selectedService: null
    }
  },
  methods: {
    selectService(serviceTitle) {
      this.selectedService = serviceTitle;
    },
    resetService() {
      this.selectedService = null;
    }
  }
}
</script>

<style scoped>
.services {
  margin: 20px 0;
}

.service-card {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.service-card img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.service-card h2 {
  margin: 10px 0;
  font-size: 14px;
}

.img-btn {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 100%;
}

@media (min-width: 768px) {
  .service-card img {
    width: 100px;
    height: 100px;
  }

  .service-card h2 {
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  .service-card img {
    width: 150px;
    height: 150px;
  }

  .service-card h2 {
    font-size: 20px;
  }
}
</style>
