<template>
  <div class="login-container">
    <div class="login-card">
      <h1>Iniciar Sesión</h1>
      <form @submit.prevent="login">
        <div class="form-group mb-3">
          <label for="email" class="form-label">Email:</label>
          <input type="email" v-model="email" class="form-control" placeholder="Ingresa tu email" required />
        </div>
        <div class="form-group mb-3">
          <label for="password" class="form-label">Contraseña:</label>
          <div class="input-group">
            <input :type="passwordFieldType" v-model="password" class="form-control" placeholder="Ingresa tu contraseña" required />
            <button type="button" class="btn btn-outline-secondary" @click="togglePasswordVisibility">
              <i :class="passwordFieldType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
            </button>
          </div>
        </div>
        <button type="submit" class="btn btn-primary w-100" :disabled="isLoading">
          {{ isLoading ? 'Iniciando sesión...' : 'Iniciar Sesión' }}
        </button>
        <p class="mt-3 text-center">
          <a href="#" @click.prevent="openPasswordResetModal">¿Olvidaste tu contraseña?</a>
        </p>
      </form>
      <div v-if="error" class="alert alert-danger mt-3">{{ error }}</div>
      <div v-if="success" class="alert alert-success mt-3">{{ success }}</div>
      <PasswordResetModal v-if="showPasswordResetModal" @close="closePasswordResetModal" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PasswordResetModal from '@/components/PasswordResetModal.vue';

export default {
  name: 'LoginView',
  components: {
    PasswordResetModal
  },
  data() {
    return {
      email: '',
      password: '',
      success: '',
      showPasswordResetModal: false,
      passwordFieldType: 'password',
      isLoading: false
    };
  },
  computed: {
    ...mapGetters('auth', ['getError']),
    error() {
      return this.getError;
    }
  },
  methods: {
    ...mapActions('auth', ['loginUser']),
    async login() {
      this.isLoading = true;
      try {
        await this.loginUser({ email: this.email, password: this.password });
        this.success = '¡Inicio de sesión exitoso!';
        setTimeout(() => {
          this.$router.push('/dashboard');
        }, 1000);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    openPasswordResetModal() {
      this.showPasswordResetModal = true;
    },
    closePasswordResetModal() {
      this.showPasswordResetModal = false;
    },
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    }
  }
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #f8f9fa;
}

.login-card {
  width: 100%;
  max-width: 400px;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
}

h1 {
  text-align: center;
  margin-bottom: 30px;
  color: #4b0082;
}
</style>
