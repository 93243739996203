<template>
  <div class="service-form p-4 bg-white rounded shadow-sm">
    <h2 class="text-center mb-3">{{ serviceTitle }}</h2>
    <div class="mb-3">
      <label for="region" class="form-label">Selecciona la Región:</label>
      <select id="region" v-model="selectedRegion" @change="fetchProvinces" class="form-select">
        <option value="">Todas las regiones</option>
        <option v-for="region in regions" :key="region.region" :value="region.region">{{ region.region }}</option>
      </select>
    </div>
    <div v-if="provinces.length" class="mb-3">
      <label for="province" class="form-label">Selecciona la Provincia:</label>
      <select id="province" v-model="selectedProvince" @change="fetchCommunes" class="form-select">
        <option value="">Todas las provincias</option>
        <option v-for="province in provinces" :key="province.nombre" :value="province.nombre">{{ province.nombre }}</option>
      </select>
    </div>
    <div v-if="communes.length" class="mb-3">
      <label for="commune" class="form-label">Selecciona la Comuna:</label>
      <select id="commune" v-model="selectedCommune" class="form-select">
        <option value="">Todas las comunas</option>
        <option v-for="commune in communes" :key="commune.codigo" :value="commune.nombre">{{ commune.nombre }}</option>
      </select>
    </div>
    <div class="d-flex gap-2 mb-2">
      <button @click="filterResults" class="btn btn-success flex-grow-1" style="background-color: #42b983;">Buscar</button>
      <button @click="clearResults" class="btn btn-warning flex-grow-1">Limpiar</button>
    </div>
    <button 
      v-if="showBackButton" 
      @click="$emit('back')" 
      class="btn btn-secondary w-100 mb-2">Volver</button>
    
    <!-- Display search results only if search was performed -->
    <div v-if="loading" class="text-center p-3">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Cargando...</span>
      </div>
    </div>
    <div v-else-if="searchPerformed && results.length === 0" class="alert alert-info mt-3">
      No se encontraron resultados con los criterios seleccionados.
    </div>
    <div v-else-if="searchPerformed" class="mt-4">
      <h3>Resultados de búsqueda</h3>
      <div class="results-grid">
        <div v-for="item in results" :key="isHospedaje ? item.id : item.uid" class="result-card">
          <div class="card h-100">
            <div class="image-container">
              <img :src="getItemImage(item)" class="card-img-top" alt="Imagen">
              <span class="promotion-badge" v-if="hasPromotion(item)">
                {{ getItemPromotion(item) }}
              </span>
            </div>
            <div class="card-body">
              <h5 class="card-title">{{ getItemTitle(item) }}</h5>
              <p class="card-text location">
                <i class="fas fa-map-marker-alt"></i> {{ item.comuna }}, {{ item.region }}
              </p>
              <p class="card-text description">{{ truncateText(getItemDescription(item), 100) }}</p>
              <div v-if="!isHospedaje" class="contact-info">
                <p v-if="item.telefono">
                  <i class="fas fa-phone"></i> 
                  <a :href="`tel:${item.telefono}`" @click="registerClick(item.uid, 'telefono')">{{ item.telefono }}</a>
                </p>
                <p v-if="item.url">
                  <i class="fas fa-globe"></i> 
                  <a :href="item.url" target="_blank" @click="registerClick(item.uid, 'web')">Visitar sitio web</a>
                </p>
              </div>
            </div>
            <div class="card-footer">
              <button @click="viewDetails(isHospedaje ? item.id : item.uid)" class="btn btn-primary w-100">Ver Detalles</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <h4 class="mt-4 mb-3">Ciudades populares:</h4>
    <CityButtons :cities="availableCities" @city-selected="selectCity" /> <br>
    <button 
      v-if="showBackButton" 
      @click="$emit('back')" 
      class="btn btn-secondary w-100 mb-2">Volver</button>
    
    <!-- Add a clear results button if there are search results displayed -->
    <button 
      v-if="searchPerformed" 
      @click="clearResults" 
      class="btn btn-outline-warning w-100 mb-2">
      Borrar resultados
    </button>
  </div>
</template>

<script>
import chileData from '@/chile.json';
import { getPublicidadesHostels, getCityRankings } from '@/api/advertisements';
import { getServices, getCitiesWithServiceType, registerServiceClick, getDefaultCities } from '@/api/services';
import CityButtons from './CityButtons.vue';

export default {
  name: 'ServiceComponent',
  components: {
    CityButtons
  },
  props: {
    serviceTitle: String,
    showBackButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      regions: chileData,
      provinces: [],
      communes: [],
      selectedRegion: '',
      selectedProvince: '',
      selectedCommune: '',
      availableCities: [],
      backupCities: ['Santiago', 'Viña del Mar', 'Concepción', 'Valparaíso', 'Iquique'],
      results: [],
      loading: false,
      searchPerformed: false,
      normalizedServiceType: '',
      isHospedaje: false
    }
  },
  async mounted() {
    // Check if this is the hospedaje component
    this.isHospedaje = this.serviceTitle === 'Hospedajes';
    
    // Normalize the service type for API calls
    this.normalizedServiceType = this.normalizeString(this.serviceTitle);
    
    // Initial fetch of cities only
    this.loading = true;
    
    try {
      if (this.isHospedaje) {
        // Use existing functionality for Hospedajes
        const response = await getCityRankings(8);
        this.availableCities = response.data && response.data.length > 0 
          ? response.data 
          : this.backupCities;
      } else {
        // For other service types, load cities from services API
        try {
          const services = await getServices({ tipo: this.normalizedServiceType });
          
          // Extract cities but don't display the services yet
          const cities = [...new Set(services.map(service => service.comuna).filter(Boolean))];
          this.availableCities = cities.length > 0 ? cities.sort() : getDefaultCities();
          
          // Store the services data for later but don't show it yet
          this.results = services || [];
          // Important: We keep searchPerformed as false so results aren't displayed
        } catch (error) {
          console.error('Error loading cities:', error);
          this.availableCities = getDefaultCities();
        }
      }
    } catch (error) {
      console.error('Error in component initialization:', error);
      this.availableCities = this.isHospedaje ? this.backupCities : getDefaultCities();
    } finally {
      this.loading = false;
    }
  },
  methods: {
    normalizeString(str) {
      return str
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^\w\s]/gi, '');
    },
    fetchProvinces() {
      const region = this.regions.find(r => r.region === this.selectedRegion);
      this.provinces = region ? region.provincias : [];
      this.communes = [];
      this.selectedProvince = '';
      this.selectedCommune = '';
    },
    fetchCommunes() {
      const province = this.provinces.find(p => p.nombre === this.selectedProvince);
      this.communes = province ? province.comunas : [];
      this.selectedCommune = '';
    },
    async loadInitialServices() {
      // Load services for the selected type without other filters
      try {
        this.loading = true;
        
        const filters = {
          tipo: this.normalizedServiceType
        };
        
        if (!this.isHospedaje) {
          const services = await getServices(filters);
          this.results = services || [];
          // We're showing initial results, but not after an explicit search
          this.searchPerformed = false;
        }
      } catch (error) {
        console.error('Error loading initial services:', error);
        this.results = [];
      } finally {
        this.loading = false;
      }
    },
    async filterResults() {
      this.loading = true;
      this.searchPerformed = true; // Set to true when user explicitly searches
      
      try {
        const filters = {};
        
        if (this.selectedRegion) filters.region = this.selectedRegion;
        if (this.selectedProvince) filters.provincia = this.selectedProvince;
        if (this.selectedCommune) filters.comuna = this.selectedCommune;
        
        if (this.isHospedaje) {
          // Use existing hospedajes functionality
          const response = await getPublicidadesHostels(filters);
          this.results = response.data;
        } else {
          // For other service types, use the services API
          filters.tipo = this.normalizedServiceType;
          const services = await getServices(filters);
          this.results = services || [];
        }
      } catch (error) {
        console.error('Error fetching results:', error);
        this.results = [];
      } finally {
        this.loading = false;
      }
    },
    async registerClick(serviceId, buttonId) {
      if (!this.isHospedaje) {
        try {
          await registerServiceClick(serviceId, buttonId);
        } catch (error) {
          console.error('Error registering click:', error);
        }
      }
    },
    selectCity(city) {
      this.selectedCommune = city;
      this.selectedRegion = '';
      this.selectedProvince = '';
      this.provinces = [];
      this.communes = [];
      this.filterResults(); // This will set searchPerformed to true
    },
    viewDetails(id) {
      // Navigate to detail view
      if (this.isHospedaje) {
        this.$router.push(`/hospedajes/${id}`);
      } else {
        this.$router.push(`/servicios/${this.normalizedServiceType}/${id}`);
      }
    },
    truncateText(text, maxLength) {
      if (!text) return '';
      return text.length > maxLength ? text.substr(0, maxLength) + '...' : text;
    },
    // Helper methods to handle different item structures
    getItemImage(item) {
      if (this.isHospedaje) {
        return item.imagen && item.imagen.length ? item.imagen[0] : 'https://via.placeholder.com/300x200?text=No+Image';
      }
      return item.imagen || 'https://via.placeholder.com/300x200?text=No+Image';
    },
    getItemTitle(item) {
      return this.isHospedaje ? item.titulo : item.nombre;
    },
    getItemDescription(item) {
      return item.descripcion || '';
    },
    hasPromotion(item) {
      if (this.isHospedaje) {
        return item.promocion && item.promocion[0] !== 'sinPromocion';
      }
      return item.promocion && item.promocion !== 'sinPromocion';
    },
    getItemPromotion(item) {
      if (this.isHospedaje) {
        return item.promocion ? item.promocion[0] : '';
      }
      return item.promocion || '';
    },
    // Add a new method to clear search results
    clearResults() {
      this.results = [];
      this.searchPerformed = false;
      this.selectedRegion = '';
      this.selectedProvince = '';
      this.selectedCommune = '';
      this.provinces = [];
      this.communes = [];
    }
  }
}
</script>

<style scoped>
.service-form {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
}

.service-form h2 {
  font-size: 24px;
  font-weight: bold;
}

.service-form .form-label {
  font-weight: bold;
}

.service-form .form-select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.service-form .btn {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
}

.results-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.result-card {
  transition: transform 0.3s ease;
}

.result-card:hover {
  transform: translateY(-5px);
}

.image-container {
  position: relative;
  height: 180px;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.promotion-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff6b6b;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: bold;
}

.description {
  font-size: 0.9rem;
  color: #212529;
  margin-bottom: 1rem;
}

.location {
  color: #6c757d;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.contact-info {
  margin-top: 10px;
}

.contact-info a {
  color: #42b983;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

/* Add a style for the gap between buttons */
.gap-2 {
  gap: 0.5rem;
}

.d-flex {
  display: flex;
}

.flex-grow-1 {
  flex-grow: 1;
}
</style>
