<template>
  <div class="modal-backdrop" @click.self="$emit('close')">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Cambiar Contraseña</h5>
        <button type="button" class="close" @click="$emit('close')">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="changePassword">
          <div class="form-group mb-3">
            <label for="currentPassword">Contraseña Actual</label>
            <input type="password" class="form-control" id="currentPassword" v-model="formData.currentPassword" required>
          </div>
          <div class="form-group mb-3">
            <label for="newPassword">Nueva Contraseña</label>
            <input type="password" class="form-control" id="newPassword" v-model="formData.newPassword" required>
            <small class="form-text text-muted">
              La contraseña debe tener al menos 8 caracteres, una mayúscula, un número y un símbolo.
            </small>
          </div>
          <div class="form-group mb-3">
            <label for="confirmNewPassword">Confirmar Nueva Contraseña</label>
            <input type="password" class="form-control" id="confirmNewPassword" v-model="formData.confirmNewPassword" required>
          </div>
          <div v-if="errorMessage" class="alert alert-danger">
            {{ errorMessage }}
          </div>
          <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-secondary me-2" @click="$emit('close')">Cancelar</button>
            <button type="submit" class="btn btn-warning">Cambiar Contraseña</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ChangePasswordModal',
  data() {
    return {
      formData: {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      },
      errorMessage: ''
    };
  },
  methods: {
    ...mapActions('auth', ['changePassword']),
    async changePassword() {
      // Reset error message
      this.errorMessage = '';
      
      // Validate passwords match
      if (this.formData.newPassword !== this.formData.confirmNewPassword) {
        this.errorMessage = 'Las contraseñas no coinciden';
        return;
      }
      
      // Validate password strength
      const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (!passwordRegex.test(this.formData.newPassword)) {
        this.errorMessage = 'La contraseña debe tener al menos 8 caracteres, una mayúscula, un número y un símbolo';
        return;
      }
      
      try {
        await this.changePassword(this.formData);
        this.$emit('close');
      } catch (error) {
        this.errorMessage = error.message || 'Error al cambiar la contraseña';
      }
    }
  }
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.modal-content {
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.modal-header {
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-body {
  padding: 1rem;
}
</style>
