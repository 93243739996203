<template>
  <div class="modal-backdrop" @click.self="$emit('close')">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Recuperar Contraseña</h5>
        <button type="button" class="close" @click="$emit('close')">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Ingresa tu dirección de correo electrónico y te enviaremos un enlace para restablecer tu contraseña.</p>
        <form @submit.prevent="sendResetEmail">
          <div class="form-group">
            <label for="resetEmail">Email:</label>
            <input
              type="email"
              v-model="email"
              class="form-control"
              id="resetEmail"
              placeholder="Ingresa tu email"
              required
            />
          </div>
          <div class="form-group text-center mt-4">
            <button type="submit" class="btn btn-primary" :disabled="isSubmitting">
              {{ isSubmitting ? 'Enviando...' : 'Enviar Enlace' }}
            </button>
          </div>
        </form>
        <div v-if="message" :class="['alert', messageType === 'success' ? 'alert-success' : 'alert-danger']">
          {{ message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sendPasswordReset } from '@/api/auth';

export default {
  name: 'PasswordResetModal',
  data() {
    return {
      email: '',
      message: '',
      messageType: '',
      isSubmitting: false
    };
  },
  methods: {
    async sendResetEmail() {
      this.isSubmitting = true;
      try {
        await sendPasswordReset(this.email);
        this.message = 'Se ha enviado un enlace de recuperación a tu correo electrónico.';
        this.messageType = 'success';
        setTimeout(() => this.$emit('close'), 3000);
      } catch (error) {
        this.message = error.message || 'Ocurrió un error al enviar el enlace de recuperación.';
        this.messageType = 'error';
      } finally {
        this.isSubmitting = false;
      }
    }
  }
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.modal-content {
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.modal-header {
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-body {
  padding: 1rem;
}
</style>
