<template>
  <div class="card">
    <div class="card-body">
      <h2 class="card-title">Perfil del Usuario</h2>
      <p class="card-text"><strong>Nombre:</strong> {{ user.nombre }}</p>
      <p class="card-text"><strong>Apellido:</strong> {{ user.apellido }}</p>
      <p class="card-text"><strong>Email:</strong> <span class="text-muted">{{ user.email }}</span></p>
      <p class="card-text"><strong>Fecha de Nacimiento:</strong> {{ user.fechaNacimiento }}</p>
      <p class="card-text"><strong>Nacionalidad:</strong> <span class="text-muted">{{ user.nacionalidad }}</span></p>
      <p class="card-text"><strong>Nickname:</strong> <span class="text-muted">{{ user.nickname }}</span></p>
      <p class="card-text"><strong>Teléfono:</strong> {{ user.telefono }}</p>
      <button class="btn btn-primary" @click="showEditModal = true">Editar Perfil</button>
      <button class="btn btn-warning mt-3" @click="showChangePasswordModal = true">Cambiar Contraseña</button>
    </div>
  </div>

  <EditProfileModal v-if="showEditModal" :user="user" @close="closeEditModal" @save="saveUserChanges" />
  <ChangePasswordModal v-if="showChangePasswordModal" @close="closeChangePasswordModal" />
</template>

<script>
import { mapState, mapActions } from 'vuex';
import EditProfileModal from './EditProfileModal.vue';
import ChangePasswordModal from './ChangePasswordModal.vue';

export default {
  name: 'ProfileCard',
  components: {
    EditProfileModal,
    ChangePasswordModal,
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showEditModal: false,
      showChangePasswordModal: false,
    };
  },
  methods: {
    ...mapActions('auth', ['updateUserProfile']),
    closeEditModal() {
      this.showEditModal = false;
    },
    closeChangePasswordModal() {
      this.showChangePasswordModal = false;
    },
    async saveUserChanges(updatedUser) {
      try {
        await this.updateUserProfile(updatedUser);
        this.closeEditModal();
      } catch (error) {
        console.error('Error updating user profile:', error);
      }
    }
  }
};
</script>

<style scoped>
.card {
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-title {
  color: #4b0082;
  margin-bottom: 20px;
  text-align: center;
}

.btn-primary {
  background-color: #4b0082;
  border-color: #4b0082;
}

.btn-primary:hover {
  background-color: #3a006b;
  border-color: #3a006b;
}
</style>
