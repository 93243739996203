<template>
  <div class="modal-backdrop" @click.self="$emit('close')">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Editar Perfil</h5>
        <button type="button" class="close" @click="$emit('close')">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="saveProfile">
          <div class="form-group mb-3">
            <label for="nombre">Nombre</label>
            <input type="text" class="form-control" id="nombre" v-model="formData.nombre" required>
          </div>
          <div class="form-group mb-3">
            <label for="apellido">Apellido</label>
            <input type="text" class="form-control" id="apellido" v-model="formData.apellido" required>
          </div>
          <div class="form-group mb-3">
            <label for="fechaNacimiento">Fecha de Nacimiento</label>
            <input type="date" class="form-control" id="fechaNacimiento" v-model="formData.fechaNacimiento">
          </div>
          <div class="form-group mb-3">
            <label for="nacionalidad">Nacionalidad</label>
            <input type="text" class="form-control" id="nacionalidad" v-model="formData.nacionalidad">
          </div>
          <div class="form-group mb-3">
            <label for="nickname">Nickname</label>
            <input type="text" class="form-control" id="nickname" v-model="formData.nickname">
          </div>
          <div class="form-group mb-3">
            <label for="telefono">Teléfono</label>
            <input type="tel" class="form-control" id="telefono" v-model="formData.telefono">
          </div>
          <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-secondary me-2" @click="$emit('close')">Cancelar</button>
            <button type="submit" class="btn btn-primary">Guardar Cambios</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditProfileModal',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formData: {
        nombre: this.user.nombre || '',
        apellido: this.user.apellido || '',
        fechaNacimiento: this.user.fechaNacimiento || '',
        nacionalidad: this.user.nacionalidad || '',
        nickname: this.user.nickname || '',
        telefono: this.user.telefono || ''
      }
    };
  },
  methods: {
    saveProfile() {
      this.$emit('save', this.formData);
    }
  }
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.modal-content {
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.modal-header {
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-body {
  padding: 1rem;
}
</style>
