<template>
  <div class="contact-form p-4 bg-white rounded shadow-sm">
    <h2 class="text-center mb-3">Contáctanos</h2>
    <form @submit.prevent="submitForm">
      <div class="mb-3">
        <label for="fullName" class="form-label">Nombre Completo:</label>
        <input type="text" v-model="contactForm.fullName" class="form-control" placeholder="Ingresa tu nombre y apellido" id="fullName" required />
      </div>

      <div class="mb-3">
        <label for="phone" class="form-label">Número de Teléfono:</label>
        <div class="input-group">
          <select v-model="contactForm.countryCode" class="form-select" required>
            <option v-for="country in countries" :key="country.code" :value="country.code">
              {{ country.name }} ({{ country.code }})
            </option>
          </select>
          <input type="text" v-model="contactForm.phone" class="form-control" placeholder="Ingresa tu número de teléfono" id="phone" required />
        </div>
      </div>

      <div class="mb-3">
        <label for="email" class="form-label">Email:</label>
        <input type="email" v-model="contactForm.email" class="form-control" placeholder="Ingresa tu correo electrónico" id="email" required />
      </div>

      <div class="mb-3">
        <label for="message" class="form-label">Mensaje o sugerencia:</label>
        <textarea v-model="contactForm.message" class="form-control" placeholder="Ingresa tu mensaje" id="message" required></textarea>
      </div>

      <button type="submit" class="btn btn-success w-100" style="background-color: #369f6b;">Enviar</button>
    </form>

    <p v-if="submissionStatus === 'loading'" class="text-center text-warning mt-2">Enviando...</p>
    <p v-if="submissionStatus === 'success'" class="text-center text-success mt-2">Formulario enviado con éxito!</p>
    <p v-if="submissionStatus === 'error'" class="text-center text-danger mt-2">Error al enviar el formulario. Por favor, inténtalo de nuevo.</p>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import countries from '@/countries.json'

export default {
  data() {
    return {
      countries: countries.countries
    }
  },
  computed: {
    ...mapState(['contactForm', 'submissionStatus'])
  },
  methods: {
    ...mapActions(['submitContactForm']),
    submitForm() {
      this.submitContactForm().then(() => {
        if (this.submissionStatus === 'success') {
          this.resetForm();
        }
      });
    },
    resetForm() {
      this.contactForm.fullName = '';
      this.contactForm.phone = '';
      this.contactForm.email = '';
      this.contactForm.message = '';
      this.contactForm.countryCode = this.countries[0].code; // Reset to the first country code
    }
  },
  created() {
    this.contactForm.countryCode = this.countries[0].code; // Set default country code
  }
}
</script>

<style scoped>
/* Mejoras generales */
.contact-form {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}
</style>
