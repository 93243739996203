<template>
  <nav class="navbar navbar-expand-lg">
    <a class="navbar-brand" href="#">
      <img src="../assets/logo.png" alt="Logo" class="logo" />
    </a>
    <button class="navbar-toggler" type="button" @click="toggleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" ref="navbarMenu">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <router-link class="nav-link" to="/" exact-active-class="">Inicio</router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#contact">Contacto</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#groups">Grupos</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#footer">Nosotros</a>
        </li>
        <!-- Auth buttons -->
        <li class="nav-item" v-if="!isAuthenticated">
          <router-link class="nav-link login-btn" to="/login">Iniciar Sesión</router-link>
        </li>
        <li class="nav-item" v-if="isAuthenticated">
          <router-link class="nav-link" to="/dashboard" @click="resetDashboard">Mi Panel</router-link>
        </li>
        <li class="nav-item" v-if="isAuthenticated">
          <a class="nav-link logout-btn" href="#" @click.prevent="logout">Cerrar Sesión</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'NavbarComponent',
  computed: {
    ...mapGetters('auth', ['isAuthenticated'])
  },
  methods: {
    ...mapActions('auth', ['logoutUser']),
    toggleNavbar() {
      const navbar = this.$refs.navbarMenu;
      navbar.classList.toggle('show'); // Bootstrap uses 'show' class for collapsing
      document.body.classList.toggle('navbar-open'); // Add class to body when navbar is toggled
    },
    logout() {
      this.logoutUser();
      this.$router.push('/');
    },
    // New method to ensure dashboard resets
    resetDashboard() {
      // If we're already on dashboard, force a reload to reset state
      if (this.$route.path === '/dashboard') {
        this.$router.go(0);
      }
    }
  }
};
</script>

<style scoped>
nav {
  display: flex;
  align-items: center;
  padding: 10px 30px; /* Reduced padding to make the navbar thinner */
  background-color: #4b0082;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1050; /* Increased z-index to ensure the navbar stays above other content */
}

nav .logo {
  height: 40px; /* Adjusted height for a smaller logo */
  margin-right: 20px;
  border-radius: 50%; /* Make the logo image round */
  object-fit: cover; /* Ensure the logo image is contained within the specified height */
}

nav a {
  font-weight: bold;
  color: #ffffff;
  margin: 0 10px;
}

nav a.router-link-exact-active {
  color: #369f6b;
}

nav a:hover {
  color: #369f6b;
}

/* Login and Logout button styles */
.login-btn, .logout-btn {
  padding: 5px 15px;
  border-radius: 20px;
  transition: all 0.3s ease;
}

.login-btn {
  background-color: #369f6b;
}

.logout-btn {
  background-color: #d32f2f;
}

.login-btn:hover, .logout-btn:hover {
  opacity: 0.9;
  color: white;
}

/* Additional styles to adjust the main content when navbar is toggled */
body.navbar-open .main-content {
  margin-top: 200px; /* Adjust this value based on the height of your expanded navbar */
}
</style>