<template>
  <div class="dashboard-container">
    <h1>Panel de Control</h1>
    
    <div class="dashboard-welcome" v-if="currentUser">
      <h2>Bienvenido, {{ currentUser.nombre || currentUser.email }}</h2>
    </div>
    
    <!-- Show profile card if selected -->
    <profile-card v-if="showProfile" :user="currentUser" @back="showProfile = false"></profile-card>
    
    <!-- Show dashboard actions if profile is not shown -->
    <div class="dashboard-actions" v-else>
      <div class="row">
        <div class="col-md-6 mb-4">
          <div class="card dashboard-card">
            <div class="card-body">
              <h5 class="card-title">Perfil</h5>
              <p class="card-text">Actualiza y administra tu información personal.</p>
              <button class="btn btn-primary" @click="showProfileCard">Ver Perfil</button>
            </div>
          </div>
        </div>
        
        <div class="col-md-6 mb-4">
          <div class="card dashboard-card">
            <div class="card-body">
              <h5 class="card-title">Mis Reservas</h5>
              <p class="card-text">Consulta tus reservas activas e históricas.</p>
              <button class="btn btn-primary">Ver Reservas</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProfileCard from '@/components/ProfileCard.vue';

export default {
  name: 'DashboardView',
  components: {
    ProfileCard
  },
  data() {
    return {
      showProfile: false
    };
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated', 'currentUser']),
  },
  methods: {
    ...mapActions('auth', ['checkAuth']),
    showProfileCard() {
      this.showProfile = true;
    },
    // New method to reset dashboard view
    resetDashboardView() {
      this.showProfile = false;
    }
  },
  async created() {
    // Verify authentication status
    const authenticated = await this.checkAuth();
    if (!authenticated) {
      this.$router.push('/login');
    }
    // Reset dashboard view when accessing this component
    this.resetDashboardView();
  },
  // Watch for route changes to reset view
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.resetDashboardView();
    });
  }
}
</script>

<style scoped>
.dashboard-container {
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

h1 {
  color: #4b0082;
  margin-bottom: 30px;
  text-align: center;
}

.dashboard-welcome {
  margin-bottom: 40px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  text-align: center;
}

.dashboard-card {
  height: 100%;
  transition: transform 0.3s, box-shadow 0.3s;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dashboard-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.card-title {
  color: #4b0082;
  font-weight: bold;
}

.btn-primary {
  background-color: #4b0082;
  border-color: #4b0082;
}

.btn-primary:hover {
  background-color: #3a006b;
  border-color: #3a006b;
}
</style>
