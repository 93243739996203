import { createStore } from 'vuex'
import axios from 'axios'
import auth from './auth'

export default createStore({
  state: {
    contactForm: {
      fullName: '',
      phone: '',
      email: '',
      message: ''
    },
    submissionStatus: ''
  },
  getters: {
    contactForm: state => state.contactForm,
    submissionStatus: state => state.submissionStatus
  },
  mutations: {
    updateContactForm(state, payload) {
      state.contactForm = { ...state.contactForm, ...payload }
    },
    setSubmissionStatus(state, status) {
      state.submissionStatus = status
    }
  },
  actions: {
    async submitContactForm({ state, commit }) {
      try {
        commit('setSubmissionStatus', 'loading')
        await axios.post('https://us-central1-mundoescort-22e70.cloudfunctions.net/api/contact', state.contactForm)
        commit('setSubmissionStatus', 'success')
      } catch (error) {
        commit('setSubmissionStatus', 'error')
      }
    }
  },
  modules: {
    auth
  }
})
