<template>
  <ServiceComponent serviceTitle="Hospedajes" :showBackButton="false" />
</template>

<script>
import ServiceComponent from './ServiceComponent.vue';

export default {
  name: 'HospedajesButtonComponent',
  components: {
    ServiceComponent
  }
}
</script>

<style scoped>
/* No additional styles needed */
</style>
