<template>
  <div class="next-services">
    <!-- New BUSCA TU HOSPEDAJE button -->
    <button :class="['btn', 'btn-primary', 'w-100', { 'btn-selected': showHospedajes }]" @click="toggleHospedajes">BUSCA TU HOSPEDAJE</button>
    <div v-if="showHospedajes" ref="hospedajesSection">
      <HospedajesButtonComponent />
    </div>
    
    <button :class="['btn', 'btn-pri', 'w-100', { 'btn-selected': showServices }]" @click="toggleServices">NUESTROS SERVICIOS</button>
    <div v-if="showServices" ref="servicesSection">
      <ServicesComponent @service-selected="selectService" />
    </div>
    
    <button :class="['btn', 'btn-pri', 'w-100', { 'btn-selected': showGroups }]" @click="toggleGroups">INGRESA A NUESTROS GRUPOS</button>
    <div v-if="showGroups" ref="groupsSection">
      <GroupComponent />
    </div>
    
    <button :class="['btn', 'btn-danger', 'w-100', { 'btn-selected': showEmergencias }]" @click="toggleEmergencias">EMERGENCIAS</button>
    <div v-if="showEmergencias" ref="emergenciasSection">
      <EmergenciasComponent />
    </div>
    
    <!-- Botón para mostrar/ocultar formulario de contacto en dispositivos pequeños -->
    <button class="btn btn-pri w-100 d-md-none" @click="toggleContactForm">Contáctanos</button>
    <div v-if="showContactForm" ref="contactFormSection">
      <ContactForm />
    </div>
  </div>
</template>

<script>
import GroupComponent from '@/views/GroupComponent.vue';
import ServicesComponent from '@/components/ServicesComponent.vue';
import ContactForm from '@/components/ContactForm.vue';
import EmergenciasComponent from '@/components/EmergenciasComponent.vue';
import HospedajesButtonComponent from '@/components/HospedajesButtonComponent.vue';

export default {
  name: 'HelloWorld',
  components: {
    GroupComponent,
    ServicesComponent,
    ContactForm,
    EmergenciasComponent,
    HospedajesButtonComponent
  },
  data() {
    return {
      showHospedajes: false,
      showServices: false,
      showGroups: false,
      showContactForm: false,
      showEmergencias: false
    }
  },
  methods: {
    // New method to toggle Hospedajes
    toggleHospedajes() {
      this.showHospedajes = !this.showHospedajes;
      if (this.showHospedajes) {
        // Hide other sections when Hospedajes is shown
        this.showServices = false;
        this.showGroups = false;
        this.showContactForm = false;
        this.showEmergencias = false;
        this.$nextTick(() => {
          this.scrollToElement(this.$refs.hospedajesSection);
        });
      }
    },
    selectService(serviceTitle) {
      this.showServices = false;
      this.$emit('service-selected', serviceTitle);
    },
    toggleServices() {
      this.showServices = !this.showServices;
      if (this.showServices) {
        // Hide other sections including Hospedajes
        this.showHospedajes = false;
        this.showGroups = false;
        this.showContactForm = false;
        this.showEmergencias = false;
        this.$nextTick(() => {
          this.scrollToElement(this.$refs.servicesSection);
        });
      }
    },
    toggleGroups() {
      this.showGroups = !this.showGroups;
      if (this.showGroups) {
        this.showHospedajes = false; // Also hide Hospedajes
        this.showServices = false;
        this.showContactForm = false;
        this.showEmergencias = false;
        this.$nextTick(() => {
          this.scrollToElement(this.$refs.groupsSection);
        });
      }
    },
    toggleContactForm() {
      this.showContactForm = !this.showContactForm;
      if (this.showContactForm) {
        this.showHospedajes = false; // Also hide Hospedajes
        this.showServices = false;
        this.showGroups = false;
        this.showEmergencias = false;
        this.$nextTick(() => {
          this.scrollToElement(this.$refs.contactFormSection);
        });
      }
    },
    toggleEmergencias() {
      this.showEmergencias = !this.showEmergencias;
      if (this.showEmergencias) {
        this.showHospedajes = false; // Also hide Hospedajes
        this.showServices = false;
        this.showGroups = false;
        this.showContactForm = false;
        this.$nextTick(() => {
          this.scrollToElement(this.$refs.emergenciasSection);
        });
      }
    },
    scrollToElement(element) {
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }
}
</script>

<style scoped>
.btn-pri {
  background-color: #4b0082;
  color: white;
  padding: 30px;
  font-size: 24px; /* Increased text size */
  font-weight: bold; /* Make text bold */
}

.btn-primary {
  background-color: #007bff; /* Blue color for the hospedaje button */
  color: white;
  padding: 30px;
  font-size: 24px;
  font-weight: bold;
}

.btn-selected {
  background-color: #6a0dad; /* Different color for selected button */
}

.btn-primary.btn-selected {
  background-color: #0056b3; /* Darker blue when selected */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1051; /* Ensure the modal is above the navbar */
}

.modal-content {
  background: white;
  padding: 0;
  border-radius: 10px;
  position: relative;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  background: white;
  padding: 0;
  border-bottom: 1px solid #ddd;
  position: sticky;
  top: 0;
  z-index: 10;
}

.close-btn {
  background: red;
  color: white;
  border: none;
  padding: 10px 0;
  cursor: pointer;
  font-size: 18px;
}

.modal-body {
  padding: 20px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.grid-item img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.grid-item p {
  text-align: center;
  margin-top: 5px;
  font-size: 14px;
  color: #333;
}

.img-btn {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 100%;
}

.btn-danger {
  background-color: #dc3545;
  color: white;
  padding: 30px;
  font-size: 24px;
  font-weight: bold;
}
</style>