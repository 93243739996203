<template>
  <div class="home">
    <ContactForm id="contact" />
    <HelloWorld @service-selected="showServiceComponent" />
    <component :is="currentServiceComponent" v-if="currentServiceComponent" />
    <footer id="footer">
      <!-- Footer content -->
    </footer>
    <button @click="scrollToTop" class="scroll-to-top">↑</button>
  </div>
</template>

<script>
import HelloWorld from '@/components/HelloWorld.vue'
import ContactForm from './ContactForm.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    ContactForm
  },
  data() {
    return {
      currentServiceComponent: null
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    showServiceComponent(serviceTitle) {
      this.currentServiceComponent = serviceTitle + 'Component';
    }
  }
}
</script>

<style scoped>
.home {
  text-align: center;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s;
}

.scroll-to-top:hover {
  opacity: 1;
}
</style>
