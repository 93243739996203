<template>
  <div id="groups" class="groups row">
    <div class="group-card col-6 col-md-4 col-lg-3" v-for="group in groups" :key="group.id">
      <button @click="selectGroup(group)" class="img-btn">
        <img :src="group.image" alt="Group Image" class="img-fluid" />
        <h2 class="text-center">{{ group.name }}</h2>
      </button>
    </div>
    <div v-if="showModal" class="modal-overlay" @click.self="closeModal">
      <div class="modal-content container">
        <div class="modal-header">
          <button class="close-btn w-100" @click="closeModal">Cerrar</button>
        </div>
        <div class="modal-body">
          <div class="group-card">
            <img :src="selectedGroup.image" alt="Group Image" class="img-fluid" />
            <h2>{{ selectedGroup.name }}</h2>
            <p>{{ selectedGroup.description }}</p>
            <div class="button-container">
              <a :href="selectedGroup.link" class="btn btn-primary btn-block" target="_blank">Unirse</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import norteImg from '@/assets/norte.png';
import centroNorteImg from '@/assets/centro-norte.png';
import santiagoImg from '@/assets/santiago.png';
import centroSurImg from '@/assets/centro-sur.png';
import surImg from '@/assets/sur.png';
import funasImg from '@/assets/funas.png';
import privadosImg from '@/assets/privados.png';
import cuartaImg from '@/assets/cuarta.png';
import conceImg from '@/assets/conce.png';
import respetoImg from '@/assets/respeto.png';
import energiaImg from '@/assets/energia.png';

export default {
  name: 'GroupComponent',
  data() {
    return {
      groups: [
        { id: 1, name: 'Angeles y Demonios ZONA NORTE', description: 'Este grupo incluye información de: Arica, Iquique, Alto Hospicio, Antofagasta, Calama, Tocopilla, Copiapó, Huasco, Vallenar y cercanos.', image: norteImg, link: 'https://chat.whatsapp.com/KEyzexXhP2B3x45Em3khO2' },
        { id: 2, name: 'Angeles y Demonios ZONA CENTRO NORTE', description: 'Este grupo incluye información de: La Serena, Coquimbo, Ovalle, Los Vilos, Valparaíso, Viña del Mar, Quilpue, Villa Alemana, Quillota, La Calera, Los Andes, San Antonio, San felipe y alrededores.', image: centroNorteImg, link: 'https://chat.whatsapp.com/FYRIsqa4xQ9J1GnmkAyMvb' },
        { id: 3, name: 'Angeles y Demonios SÓLO SANTIAGO', description: 'Este grupo incluye información de: Santiago, Providencia, Las Condes, Ñuñoa, Recoleta, Estación Central, La Cisterna, La Reina, San Bernardo, Maipú, Puente Alto, La Granja, Buin, Melipilla y todas las estaciones del metro.', image: santiagoImg, link: 'https://chat.whatsapp.com/C916qtKxi5l2VCaydgpBOT' },
        { id: 4, name: 'Angeles y Demonios ZONA CENTRO SUR', description: 'Este grupo incluye información de: Rancagua, San Fernando, Talca Linares Curico, Santa Cruz, Chillan, Concepción, Los Ángeles.', image: centroSurImg, link: 'https://chat.whatsapp.com/CQ1fHAgEqls7zd2pWs7nx1' },
        { id: 5, name: 'Angeles y Demonios ZONA SUR', description: 'Este grupo incluye información de: Temuco, Angol, Pucón, Villarrica, Valdivia Osorno, Puerto Montt, Chiloé, Coyhaique, Puerto Aysén, Punta Arenas, Puerto Natales y cercanos.', image: surImg, link: 'https://chat.whatsapp.com/HXD4GJk0ao4DTeRiGDnXgP' },
        { id: 6, name: 'PRIVADOS CHILE', description: 'Este grupo incluye información de: Privados y Agencias con lugar propio a lo largo de chile', image: privadosImg, link: 'https://chat.whatsapp.com/Bxnu3zNojm5IYJpUV8lH1O' },
        { id: 7, name: 'Angeles y Demonios FUNAS CHILE', description: 'Este grupo incluye información de: Funas y reportes de clientes a nivel nacional, de Arica a Punta Arenas.', image: funasImg, link: 'https://chat.whatsapp.com/FTeRVuXz1xF6VTUvC31Llm' },
        { id: 8, name: 'LA SERENA, COQUIMBO Y OVALLE', description: 'Este grupo incluye información de: Este grupo tiene información exclusiva de La Serena, Coquimbo y Ovalle.', image: cuartaImg, link: 'https://chat.whatsapp.com/IysdXA0dWqVEdS8qb9ccSN' },
        { id: 10, name: 'ARRIENDOS CONCEPCIÓN Y OTRAS CIUDADES', description: 'Este grupo incluye información de: Funas y reportes de clientes a nivel nacional, de Arica a Punta Arenas.', image: conceImg, link: 'https://chat.whatsapp.com/Jzjs5QCIf0f9JOvvd8cqzM' },
        { id: 9, name: 'SIN REGLAS, PERO CON RESPETO', description: 'Este grupo incluye información de: Este grupo es para datos, funas, comercio y lo que quieran, pero con respeto.', image: respetoImg, link: 'https://chat.whatsapp.com/IxH8UDjFCNBDsGhFIlh73x' },
        { id: 11, name: '2025 CON ENERGÍA', description: 'Este grupo incluye información de: Información de lo que quieras en un ambiente muy relajado.', image: energiaImg, link: 'https://chat.whatsapp.com/GC2CujYTk19AQBFE2FfwQs' }
      ],
      showModal: false,
      selectedGroup: null
    }
  },
  methods: {
    selectGroup(group) {
      this.selectedGroup = group;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.selectedGroup = null;
    }
  }
}
</script>

<style scoped>
.groups {
  margin: 20px 0;
}

.group-card {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.group-card img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.group-card h2 {
  margin: 10px 0;
  font-size: 14px;
}

.img-btn {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 100%;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1051;
}

.modal-content {
  background: white;
  padding: 0;
  border-radius: 10px;
  position: relative;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  background: white;
  padding: 0;
  border-bottom: 1px solid #ddd;
  position: sticky;
  top: 0;
  z-index: 10;
}

.close-btn {
  background: red;
  color: white;
  border: none;
  padding: 10px 0;
  cursor: pointer;
  font-size: 18px;
}

.modal-body {
  padding: 20px;
}

.button-container {
  margin-top: auto;
}

.group-card a {
  background-color: #42b983;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  display: block;
  margin-top: 10px;
}

.group-card a:hover {
  background-color: #369f6b;
}

@media (min-width: 768px) {
  .group-card img {
    width: 100px;
    height: 100px;
  }

  .group-card h2 {
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  .group-card img {
    width: 150px;
    height: 150px;
  }

  .group-card h2 {
    font-size: 20px;
  }
}
</style>
