<template>
  <div class="city-buttons">
    <button v-for="city in cities" :key="city" @click="selectCity(city)" class="city-btn">{{ city }}</button>
  </div>
</template>

<script>
export default {
  name: 'CityButtons',
  props: ['cities'],
  methods: {
    selectCity(city) {
      this.$emit('city-selected', city);
    }
  }
}
</script>

<style scoped>
.city-buttons {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.city-btn {
  background-color: gold;
  color: black;
  border: 2px solid black;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  flex: 1 1 calc(25% - 20px); /* Adjust width based on screen size */
  box-sizing: border-box;
  transition: background-color 0.3s;
}

.city-btn:hover {
  background-color: #ffd700;
}

@media (max-width: 768px) {
  .city-btn {
    flex: 1 1 calc(50% - 20px); /* Adjust width for smaller screens */
  }
}

@media (max-width: 480px) {
  .city-btn {
    flex: 1 1 calc(50% - 20px); /* Minimum 2 buttons per row for very small screens */
  }
}
</style>
