<template>
  <div class="container my-4">
    <div class="row justify-content-center align-items-center">
      <!-- imagen del logo -->
      <div class="col-md-6 col-12 mb-4">
        <div class="contact-text p-4 bg-light text-center rounded shadow-sm">
          <img src="../assets/logo.png" alt="Logo" class="logo img-fluid" />
          <h1 class="mb-3">¡Bienvenid@ a nuestro sitio web!</h1>
        </div>
      </div>

      <!-- Formulario de contacto -->
      <div class="col-md-6 col-12 d-none d-md-block">
        <ContactForm />
      </div>
    </div>
  </div>
</template>

<script>
import ContactForm from '@/components/ContactForm.vue';

export default {
  components: {
    ContactForm
  }
}
</script>

<style scoped>
/* Mejoras generales */
.contact-text, .contact-form {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}

img.logo {
  width: 100%;
  height: auto;
  border-radius: 50%;
  animation: rotateLogo 5s infinite linear;
  margin-bottom: 20px;
}

@keyframes rotateLogo {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

@media (min-width: 576px) {
  img.logo {
    width: 80%;
  }
}

@media (min-width: 768px) {
  img.logo {
    width: 60%;
  }
}

@media (min-width: 992px) {
  img.logo {
    width: 50%;
  }
}

.contact-form h1 {
  font-size: 1.8rem;
}

@media (max-width: 768px) {
  .contact-text, .contact-form {
    text-align: center;
  }
}

.btn-pri {
  background-color: #4b0082;
  color: white;
  padding: 30px;
  font-size: 24px; /* Increased text size */
  font-weight: bold; /* Make text bold */
}

.btn-selected {
  background-color: #6a0dad; /* Different color for selected button */
}

/* Ensure the contact form is not overlapped by the navbar */
body.navbar-open .contact-form {
  margin-top: 200px; /* Adjust this value based on the height of your expanded navbar */
}
</style>
