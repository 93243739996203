import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL || 'https://us-central1-mundo-informativo.cloudfunctions.net/api';

/**
 * Normalizes a string by removing accents, converting to lowercase, and removing special characters
 */
const normalizeString = (str) => {
  return str
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^\w\s]/gi, '');
};

/**
 * Gets services with optional filters
 */
export const getServices = async (filters = {}) => {
  try {
    const params = new URLSearchParams();
    
    if (filters.tipo) {
      // Normalize the type for consistency
      params.append('tipo', normalizeString(filters.tipo));
    }
    if (filters.region) params.append('region', filters.region);
    if (filters.provincia) params.append('provincia', filters.provincia);
    if (filters.comuna) params.append('comuna', filters.comuna);
    params.append('onlyEnabled', true); // Only show enabled services by default
    
    const url = `${API_URL}/services${params.toString() ? '?' + params.toString() : ''}`;
    const response = await axios.get(url);
    
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error(response.data.message || 'Error al obtener servicios');
    }
  } catch (error) {
    console.error('Error en getServices:', error);
    throw error;
  }
};

/**
 * Gets a specific service by ID
 */
export const getServiceById = async (id, buttonId = null) => {
  try {
    let url = `${API_URL}/services/${id}`;
    if (buttonId) {
      url += `?buttonId=${buttonId}`;
    }
    
    const response = await axios.get(url);
    
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error(response.data.message || 'Error al obtener el servicio');
    }
  } catch (error) {
    console.error('Error en getServiceById:', error);
    throw error;
  }
};

/**
 * Gets cities with services of a specific type
 */
export const getCitiesWithServiceType = async (serviceType) => {
  try {
    const services = await getServices({ tipo: serviceType });
    
    // Extract unique cities
    const cities = [...new Set(services.map(service => service.comuna).filter(Boolean))];
    return cities.sort();
  } catch (error) {
    console.error('Error fetching cities for service type:', error);
    return [];
  }
};

/**
 * Registers a click on a service
 */
export const registerServiceClick = async (id, buttonId) => {
  try {
    const response = await axios.post(`${API_URL}/services/${id}/click`, { buttonId });
    
    if (response.data.success) {
      return response.data;
    } else {
      throw new Error(response.data.message || 'Error al registrar clic');
    }
  } catch (error) {
    console.error('Error en registerServiceClick:', error);
    return { success: false };
  }
};

/**
 * Helper function to get a fallback city list for a service type
 */
export const getDefaultCities = () => {
  return ['Santiago', 'Viña del Mar', 'Concepción', 'Valparaíso', 'Iquique', 'Antofagasta'];
};
